export { formatTime, formatDate } from '@/utils/index'
export { NumToChinese } from '@/utils/util'

/**
* Show plural label if time is plural number
* @param {number} time
* @param {string} label
* @return {string}
*/
function pluralize (time, label) {
    if (time === 1) {
        return time + label
    }
    return time + label + 's'
}

/**
 * @param {number} time
 */
export function timeAgo (time) {
    const between = Date.now() / 1000 - Number(time)
    if (between < 3600) {
        return pluralize(~~(between / 60), ' minute')
    } else if (between < 86400) {
        return pluralize(~~(between / 3600), ' hour')
    } else {
        return pluralize(~~(between / 86400), ' day')
    }
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter (num) {
    return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}


/**
 * @param {value} 时间
 * @param {format} 格式 yyyy-mm-dd hh:mm:ss
 */
export function toConvertTime (value, format) {
    let date = 0
    if (value) {
        date = new Date(value)
    } else {
        date = new Date()
    }
    let Y = date.getFullYear()
    let M = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    let D = `0${date.getDate()}`.slice(-2)
    let h = `0${date.getHours()}`.slice(-2)
    let m = `0${date.getMinutes()}`.slice(-2)
    let s = `0${date.getSeconds()}`.slice(-2)
    if (format === undefined) {
        return `${Y}-${M}-${D} ${h}:${m}:${s}`
    } else if (format === 'yyyy-mm-dd') {
        return `${Y}-${M}-${D}`
    } else if (format === 'yyyy-mm') {
        return `${Y}-${M}`
    } else if (format === 'mm-dd') {
        return `${M}-${D}`
    } else if (format === 'dd') {
        return `${D}`
    } else if (format === 'yyyy') {
        return `${Y}`
    } else if (format === 'hh:mm:ss') {
        return `${h}:${m}:${s}`
    } else if (format === 'hh:mm') {
        return `${h}:${m}`
    } else if (format === 'mm-dd hh:mm') {
        return `${M}-${D} ${h}:${m}`
    } else if (format === 'y年m月') {
        return `${Y}年${+M}月`
    } else if (format === 'mm月dd日') {
        return `${M}月${D}日`
    } else if (format === 'm月d日') {
        return `${+M}月${+D}日`
    } else if (format === 'm月') {
        return `${+M}月`
    } else {
        return ""
    }
}

/**
 * @name: 数字彩 经常碰到 小于10 前面带上0
 * @test: test font
 * @msg: 
 * @param {type} number
 * @return: string
 */

export const isAddZero = (num) => {
    if (!num && num !== 0) return ''
    return num < 10 ? '0' + +num : num + ''
}

/**
 * 切割字符串 使用String.substr(from, ?length)
 * @param {Integer} val 
 */
export function subStr (val, from, end = undefined) {
    return val ? String(val.substr(from, end)) : val

}

/**
 * @name: 2019-1-1格式转星期几
 * @test: test font
 * @msg:
 * @param {type} number
 * @return: string
 */

export const getWeek = (date) => {
    if (!date) return ''
    let dateArr = date.split('-')
    let val = new Date(
        dateArr[0],
        parseInt(dateArr[1] - 1),
        dateArr[2],
    )
    return `星期${'日一二三四五六'.charAt(val.getDay())}`
}
