/*
 * @Descripttion: 根据code 区分路由大类
 */
// code     大类      // lottoryId
// 10001	时时彩类  // 10001 重庆欢乐生肖 10002 新疆时时彩 // 新加的玩法 10020 极速时时彩 10021幸运时时彩3 10022幸运时时彩5
// 10002	快乐十分  // 10003 广东快乐十分 10004 重庆幸运农场 // 新加的玩法  10027 极速快乐十分
// 10003	赛车类    //  10005 北京PK10 // 新加的玩法 10023 极速赛车 10024 幸运赛车3 10025 幸运赛车5 10030 幸运飞艇
// 10004	11选5    // 10007 广东11选5 // 新加的玩法 10026 极速11选5
// 10005	六合彩类  // 10008 香港六合彩
// 10006	快乐8    // 10009 北京快乐8 // 新加的玩法 10028 极速快乐8
// 10007	快3类    // 10010 江苏快3 10011 北京快3 10012 广西快3 // 新加的玩法 10029 极速快3
// 10008	全国彩   // 10013 福彩3D 10014 双色球 10015 七乐彩 10016 大乐透 10017 排列三 10018 排列五 10019 七星彩
import {F} from '@/config/hk-caption'

// 后端给的是 lottoId 区分类
export const lottoIdConvertRoute = (code) => {
    let result
    switch (typeof code === 'string' ? code : code + '') {
        case '10001': // 重庆欢乐生肖
        case '10002': // 新疆时时彩
        case '10020': // 极速时时彩
        case '10021': // 幸运时时彩3
        case '10022': // 幸运时时彩5
            result = 'ssc'
            break
        case '10003': // 广东快乐十分
        case '10004': // 重庆幸运农场
        case '10027': // 极速快乐十分
            result = 'kl10'
            break
        case '10005': // 北京PK10
        case '10023': // 极速赛车
        case '10024': // 幸运赛车3
        case '10025': // 幸运赛车5
        case '10030': // 幸运飞艇
            result = 'pk10'
            break
        case '10007': // 广东11选5
        case '10026': // 极速11选5
            result = '11x5'
            break
        case '10008': // 香港六合彩
            result = 'hklotto'
            break
        case '10009': // 北京快乐8
        case '10028': // 极速快乐8
            result = 'k8'
            break
        case '10010': // 江苏快3
        case '10011': // 北京快3
        case '10012': // 广西快3
        case '10029': // 极速快3
            result = 'k3'
            break
        case '10013':
            // 标准菜每个都不一样，单独拎出来 福彩3D
            result = 'fc3d'
            break
        case '10014':
            // 标准菜每个都不一样，单独拎出来 ssq福彩双色球
            result = 'fcssq'
            break
        case '10015':
            // 标准菜每个都不一样，单独拎出来 qlc七乐彩
            result = 'fc7lc'
            break
        case '10016':
            // 标准菜每个都不一样，单独拎出来 dlt
            result = 'cjdlt'
            break
        case '10017':
        case '10018':
            // 标准菜每个都不一样，单独拎出来 pl3 pl5
            result = 'pl'
            break
        case '10019':
            // 标准菜每个都不一样，单独拎出来 七星彩
            result = 'tc7xc'
            break
        default:
            console.log('路由没有匹配上')
    }
    return result
}

/**
 * @name: 是否是全国彩 根据 lotteryID
 * @test: test font
 * @msg: 
 * @param {type} 
 * @return: 
 */

export const normalLotteryId = ['10013', '10014', '10015', '10016', '10017', '10018', '10019']


/**
 * @name: 根据 lotteryId 判断 是什么彩类
 * @test: test font
 * @msg: 
 * @param {type} lotteryID
 * @return: officalId
 */
// 10001	时时彩类  // 10001 重庆欢乐生肖 10002 新疆时时彩 // 新加的玩法 10020 极速时时彩 10021幸运时时彩3 10022幸运时时彩5
// 10002	快乐十分  // 10003 广东快乐十分 10004 重庆幸运农场 // 新加的玩法  10027 极速快乐十分
// 10003	赛车类    //  10005 北京PK10 // 新加的玩法 10023 极速赛车 10024 幸运赛车3 10025 幸运赛车5
// 10004	11选5    // 10007 广东11选5 // 新加的玩法 10026 极速11选5
// 10005	六合彩类  // 10008 香港六合彩
// 10006	快乐8    // 10009 北京快乐8 // 新加的玩法 10028 极速快乐8
// 10007	快3类    // 10010 江苏快3 10011 北京快3 10012 广西快3 // 新加的玩法 10029 极速快3
// 10008	全国彩   // 10013 福彩3D 10014 双色球 10015 七乐彩 10016 大乐透 10017 排列三 10018 排列五 10019 七星彩


export const lotteryIdConvertOfficalId = (lotteryId) => {
    let result
    switch (lotteryId) {
        case '10001':
        case '10002':
        case '10020':
        case '10021': // ssc
            result = '10001'
            break
        case '10003':
        case '10004':
        case '10027':// kl10
            result = '10002'
            break
        case '10005':
        case '10023':
        case '10024':
        case '10025': // pk10
            result = '10003'
            break
        case '10007':
        case '10026'://11x5
            result = '10004'
            break
        case '10008': // hk
            result = '10005'
            break
        case '10009':
        case '10028':// k8
            result = '10006'
            break
        case '10010':
        case '10011':
        case '10012':
        case '10029':
            result = '10007'
            break
        case '10013':
        case '10014':
        case '10015':
        case '10016':
        case '10017':
        case '10018':
        case '10019':
            result = '10008'
            break
        default:
            console.log('cant write lotteryId:' + lotteryId)
            break
    }
    return result
}

export const p = '2'
export const d = '2'
export const e = '3'
export const M = '1'
export const H = 'e'
export const a = 'W'
export const V = 'e'
export const z = 'r'
export const I = '2'
export const y = '3'
export const R = '4'
export const Q = 't'
export const u = 'y'
export const Z = 'u'
export const T = 'i'
export const O = 'o'
export const P = 'p'
export const t = '5'
export const n = '6'
export const l = 'a'
export const E = F([p,d,e,M,H,a,V,z,I,y,R,Q,u,Z,T,O,P,t,n,l])
/**
 * @name: 彩种号码个数
 * @test: test font
 * @msg: 
 * @param {type} 
 * @return: 
 */
// 10001	时时彩类  // 10001 重庆欢乐生肖 10002 新疆时时彩 // 新加的玩法 10020 极速时时彩 10021幸运时时彩3 10022幸运时时彩5
// 10002	快乐十分  // 10003 广东快乐十分 10004 重庆幸运农场 // 新加的玩法  10027 极速快乐十分
// 10003	赛车类    //  10005 北京PK10 // 新加的玩法 10023 极速赛车 10024 幸运赛车3 10025 幸运赛车5
// 10004	11选5    // 10007 广东11选5 // 新加的玩法 10026 极速11选5
// 10005	六合彩类  // 10008 香港六合彩
// 10006	快乐8    // 10009 北京快乐8 // 新加的玩法 10028 极速快乐8
// 10007	快3类    // 10010 江苏快3 10011 北京快3 10012 广西快3 // 新加的玩法 10029 极速快3
// 10008	全国彩   // 10013 福彩3D 10014 双色球 10015 七乐彩 10016 大乐透 10017 排列三 10018 排列五 10019 七星彩
export const officalIdNumLength = (officalId) => {
    let result
    switch (officalId + '') {
        case '10001':
            result = 5
            break
        case '10002':
            // kl10
            result = 8
            break
        case '10003':
            // pk10
            result = 10
            break

        case '10004':
            // 11x5
            result = 5
            break
        case '10005':
            // hk
            result = 7
            break
        case '10006':
            // k8
            result = 20
            break
        case '10007':
            // k3
            result = 3
            break
        // case '10008':
        //     // normal
        //     result = 9
        //     break
        default:
            break
    }
    return result
}
export const D = 'aQWsdPfJklmM89S'
/**
 * @name: 根据 lotteryId 判断全国彩球个数
 * @test: test font
 * @msg: 
 * @param {type} string
 * @return: number
 */
export const lotteryIdNormalLength = (lotteryId) => {
    let result
    switch (lotteryId + '') {
        case '10013':
            //f3d
            result = 3
            break
        case '10014':
            // ssq
            result = 7
            break
        case '10015':
            //qlc
            result = 8
            break
        case '10016':
            // dlt
            result = 7
            break
        case '10017':
            // pl3
            result = 3
            break
        case '10018':
            // pl5
            result = 5
            break
        case '10019':
            // qxc
            result = 7
            break
        default:
            break
    }
    return result
}
