import api from "@/api/api"
import moduleBase from "../common/moduleBase"

const config = {
    state: {
        lotteryList: [], //彩票列表
    },
    actions: {
        async getLotteryList ({ commit, dispatch }, payload) {
            const res = await api.get("/config/getLotteryOpts", payload)
            if (res) {
                await commit({
                    type: "set",
                    payload: {
                        lotteryList: res,
                    }
                })
            }
            return res
        },
    }
}

export default {
    namespaced: true,
    ...moduleBase(config)
}