

// 随机数
let randomToken = ''

// 存储随机数
const setRandomToken = (val) => {
    randomToken = val
}

// 获取随机数
const getRandomToken = () => {
    return randomToken
}

// 存储sessionStorage
const setStorage = (token_name, val) => {
    sessionStorage.setItem(`${getRandomToken()}_${token_name}`, val)
}

// 获取sessionStorage
const getStorage = (token_name) => {
    return sessionStorage.getItem(`${getRandomToken()}_${token_name}`)
}

// 删除sessionStorage
const removeStorage = (token_name) => {
    return sessionStorage.removeItem(`${getRandomToken()}_${token_name}`)
}

// 清空sessionStorage
const clearStorage = () => {
    sessionStorage.clear()
}

import {F} from '@/config/hk-caption'
export const G = 's'
export const A = 'd'
export const q = 'f'
export const N = 'J'
export const I = 'K'
export const m = 'L'
export const v = 'S'
export const f = 'F'
export const Z = 'C'
export const K = 'S'
export const W = '2'
export const k = '3'
export const R = 'S'
export const M = 'V'
export const n = 's'
export const h = 'd'
export const y = 'd'
export const w = '2'
export const t = '1'
export const P = '2'

export const S = F([G,A,q,N,I,m,v,f,Z,K,W,k,R,M,n,h,y,w,t,P])

export default {
    setRandomToken,
    getRandomToken,
    setStorage,
    getStorage,
    removeStorage,
    clearStorage
}