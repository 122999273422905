<template>
    <div id="app" :class="[themeClass]">
        <router-view />
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    components: {},
    data() {
        return {
            themeObj: {
                1: 'blue',
                2: 'green',
                3: 'red'
            }
        };
    },
    computed: {
        ...mapState({
            theme: ({ user }) => user.theme
        }),
        themeClass() {
            return `theme-${this.themeObj[this.theme || 1]}`;
        },
    },
    created() {},
    mounted() {}
};
</script>

<style lang="scss" scoped>
#app {
    // -webkit-overflow-scrolling: touch;
}
</style>
<style lang="scss">
@import "./assets/css/public";
@import "./assets/css/base";
@import "./assets/css/vant";
@import "./assets/css/lose-post";
</style>
