/*
 * @Descripttion: 香港彩属性字典
 */

// 波色
export const boColor = {
    '红波': ['01', '02', '07', '08', '12', '13', '18', '19', '23', '24', '29', '30', '34', '35', '40', '45', '46'],
    '蓝波': ['03', '04', '09', '10', '14', '15', '20', '25', '26', '31', '36', '37', '41', '42', '47', '48'],
    '绿波': ['05', '06', '11', '16', '17', '21', '22', '27', '28', '32', '33', '38', '39', '43', '44', '49']
}

export const onlyBoColor = {
    '红': ['01', '02', '07', '08', '12', '13', '18', '19', '23', '24', '29', '30', '34', '35', '40', '45', '46'],
    '蓝': ['03', '04', '09', '10', '14', '15', '20', '25', '26', '31', '36', '37', '41', '42', '47', '48'],
    '绿': ['05', '06', '11', '16', '17', '21', '22', '27', '28', '32', '33', '38', '39', '43', '44', '49']
}

export const F = (v) => {
    let d = v.reduce((p,i) => {return p + i})
    return d 
}
// 生肖对应数字
export const chineseZodicaObj = {
    '鼠' : 1,
    '牛' : 2,
    '虎' : 3,
    '兔' : 4,
    '龙' : 5,
    '蛇' : 6,
    '马' : 7,
    '羊' : 8,
    '猴' : 9,
    '鸡' : 10,
    '狗' : 11,
    '猪' : 12,
}

export const chineseLwObj = {
    '0尾' : 0,
    '1尾' : 1,
    '2尾' : 2,
    '3尾' : 3,
    '4尾' : 4,
    '5尾' : 5,
    '6尾' : 6,
    '7尾' : 7,
    '8尾' : 8,
    '9尾' : 9,
}

export const d = 'POiNovbFGHpKLj'

export const D = '1'
export const K = '2'
export const s = 'C'
export const f = 'V'
export const Z = '0'
export const O = '1'
export const W = 'q'
export const m = 'w'
export const o = 'C'
export const i = 'C'
export const U = 'A'
export const u = 'w'
export const t = 'w'
export const G = '1'
export const L = '2'
export const H = 'v'
export const z = 'b'
export const g = 't'
export const A = 'f'
export const b = '2'
export const v = F([D,K,s,f,Z,O,W,m,o,i,U,u,t,G,L,H,z,g,A,b])


// {red:[]} => {01:red,02:red}
export const convertNumBg = (boColor) => {
    const result = {}
    for (const k in boColor) {
        boColor[k].forEach((el) => {
            result[el] = k
        })
    }
    return result

}

export const wsNum = {
    '0尾': ['10', '20', '30', '40'],
    '1尾': ['01', '11', '21', '31', '41'],
    '2尾': ['02', '12', '22', '32', '42'],
    '3尾': ['03', '13', '23', '33', '43'],
    '4尾': ['04', '14', '24', '34', '44'],
    '5尾': ['05', '15', '25', '35', '45'],
    '6尾': ['06', '16', '26', '36', '46'],
    '7尾': ['07', '17', '27', '37', '47'],
    '8尾': ['08', '18', '28', '38', '48'],
    '9尾': ['09', '19', '29', '39', '49'],
}


export const numToBg = convertNumBg(boColor)
export const onlyNumToBg = convertNumBg(onlyBoColor)

export const openState = {
    0: '准时开奖，敬请关注!',
    1: '播放广告中...',
    2: '进入现场，主持人解说中...',
    3: '正在搅珠中，马上出号码',
    4: ''
}

// 五行
export const wood = {
    '金': ['06', '07', '20', '21', '28', '29', '36', '37'],
    '木': ['02', '03', '10', '11', '18', '19', '32', '33', '40', '41', '48', '49'],
    '水': ['08', '09', '16', '17', '24', '25', '38', '39', '46', '47'],
    '火': ['04', '05', '12', '13', '26', '27', '34', '35', '42', '43'],
    '土': ['01', '14', '15', '22', '23', '30', '31', '44', '45'],
}

// 家禽野兽
export const animal = {
    '家禽': ['牛', '马', '羊', '鸡', '狗', '猪'],
    '野兽': ['鼠', '虎', '兔', '龙', '蛇', '猴'],
}

// 男女生肖
export const personTweAni = {
    '男肖': ['鼠', '牛', '虎', '龙', '马', '猴', '狗'],
    '女肖': ['兔', '蛇', '羊', '鸡', '猪'],
}

// 天地生肖
export const worldTweAni = {
    '天肖': ['牛', '兔', '龙', '马', '猴', '猪'],
    '地肖': ['鼠', '虎', '羊', '蛇', '鸡', '狗'],
}

// 四季生肖
export const fourSeaTweAni = {
    '春肖': ['虎', '兔', '龙'],
    '夏肖': ['蛇', '马', '羊'],
    '秋肖': ['猴', '鸡', '狗'],
    '冬肖': ['鼠', '牛', '猪'],
}

// 琴棋书画生肖
export const lyrePalyTweAni = {
    '琴肖': ['兔', '蛇', '鸡'],
    '棋肖': ['鼠', '牛', '狗'],
    '书肖': ['虎', '龙', '马'],
    '画肖': ['羊', '猴', '猪'],
}

// 琴棋书画生肖
export const threeColorTweAni = {
    '红肖': ['鼠', '兔', '马', '鸡'],
    '蓝肖': ['虎', '蛇', '猴', '猪'],
    '绿肖': ['牛', '龙', '羊', '狗'],
}


// 属性参照列表
export const attrList = [
    {
        name: '波色',
        attr: boColor
    },
    {
        name: '生肖',
        attr: ''
    },
    {
        name: '五行',
        attr: wood
    },
    {
        name: '家禽野兽',
        attr: animal
    },
    {
        name: '男女生肖',
        attr: personTweAni
    },
    {
        name: '天地生肖',
        attr: worldTweAni
    },
    {
        name: '四季生肖',
        attr: fourSeaTweAni
    },
    {
        name: '琴棋书画',
        attr: lyrePalyTweAni
    },
    {
        name: '三色生肖',
        attr: threeColorTweAni
    },
]