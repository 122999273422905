<template>
    <div class="maintain flex-col-c">
        <div class="maintain-img"></div>
        <h3 class="mt-26">{{maintainText.content}}</h3>
        <div class="maintain-hint mt-10">如果您对我们公司有任何疑问，请发送电子邮件至</div>
        <div class="maintain-hint"><span>{{maintainText.email}}</span> 与我们联系，很抱歉给您带来不便</div>
        
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState({
            maintainText: ({ user }) => user.maintainText
        }),
        valText() {
            return this.maintainText || sessionStorage.getItem("maintainText");
        }
    },
    created() {
        this.getConfig()
    },
    methods: {
        getConfig() {
            this.$axios.get("/config/getSiteConfig").then(res => {
                this.$router.replace("/login")
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.maintain {
    height: 100vh;
    .maintain-img {
        height: 195px;
        width: 202px;
        height: 155px;
        margin: 92px auto 70px;
        @include themify($themes) {
            background: themed('maintain') no-repeat center / contain;
        }
    }
    h3 {
        font-size: 15px;
        color: #111626;
        line-height: 20px;
        text-align: center;
        padding: 0 38px;
    }
    .maintain-hint {
        font-size: 13px;
        color: #858B9C;
        text-align: center;
        line-height: 18px;
        padding: 0 44px;
        span {
            color: #FF4D4A;
        }
    }
    .mt-10 {
        margin-top: 10px;
    }
    .mt-26 {
        margin-top: 26px;
    }
}
</style>
