import Vue from "vue"
import VueRouter from "vue-router"
import maintain from '@/views/errorPage/maintain'
import errorPage404 from '@/views/errorPage/errorPage404'
import modules from "./modules"

const routeModules = Object.keys(modules).reduce((f, t) => [...f, ...(t !== "footer" ? modules[t] : [])], [])

Vue.use(VueRouter)

//  hastab: 有tabbar, hasTriangle: 有title箭头, hastab:没有返回箭头
const routes = [
    {
        path: "",
        name: "index",
        redirect: '/hall',
        component: () => import("@/views/index.vue"),
        children: [
            ...routeModules,
            {
                path: "/login",
                name: "login",
                meta: {
                    title: '用户登录',
                    noArrow: true
                },
                component: () => import("@/views/Main/login")
            },
            {
                path: '/hall',
                name: '彩票大厅',
                meta: {
                    hastab: true,
                    noHead: true,
                    isForbidden: true,
                },
                component: () => import('@/views/Main/hall')
            },
            {
                path: '/nopay',
                name: '未结明细',
                meta: {
                    hastab: true,
                    noArrow: true,
                    isForbidden: true,
                    title: '未结明细',
                },
                component: () => import('@/views/Main/nopay')
            },
            {
                path: '/open/:categoryId/:code/:id',
                name: '开奖历史',
                meta: {
                    hastab: true,
                    isForbidden: true,
                    noHead: true,
                },
                component: () => import('@/views/OpenLottory/open')
            },
            {
                path: '/moropen/:categoryId/:code/:id',
                name: '更多开奖历史',
                meta: {
                    isForbidden: true,
                    hastab: false
                },
                component: () => import('@/views/OpenLottory/open')
            },
            {
                path: 'choose/:categoryId/:code/:id',
                name: '选号下注',
                meta: {
                    isForbidden: true,
                    noHead: true,
                },
                component: () => import('@/views/CodeHall/index')
            },
            {
                path: '/db/:categoryId/:code/:id',
                name: '两面长龙',
                meta: {
                    isForbidden: true,
                    noHead: true,
                },
                component: () => import('@/views/CodeHall/component/lot-db')
            }
        ]
    },
    {
        path: '/appAgree',
        name: "appAgree",
        component: () => import('@/views/Main/appAgree')
    },
    {
        path: "/appRules/:code/:id",
        name: "appRules",
        component: () => import('@/views/Main/appRules')
    },
    {
        path: '/maintain',
        name: "maintain",
        component: maintain
    },
    { path: '*', component: errorPage404 }
]


const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})

// const orignPush = VueRouter.prototype.push;
// VueRouter.prototype.Push = function push(location) {
//     return orignPush.call(this,location).catch(err => {
//         console.log("错误",err);
//     })
// }

export default router
