<template>
    <div class="fdf-round flex-col-c-c">
        <div class="fdf-img"></div>
        <h3>彩票不见了</h3>
        <p>
            页面找不到，将在<span style="color:#EF5250;">{{ time }}</span
            >秒后自动返回首页
        </p>
        <van-button class="refresh-btn" @click="goHome()">返回首页</van-button>
    </div>
</template>
<script>
export default {
    name: "errorPage404",
    data() {
        return {
            time: 2
        };
    },
    methods: {
        goHome() {
            this.$router.replace("/");
        },
        clearIntervals() {
            clearInterval(this.timer);
        }
    },
    mounted() {
        this.timer = setInterval(() => {
            this.time -= 1;
            if (this.time <= 0) {
                this.goHome();
                this.clearIntervals();
            }
        }, 1000);
    },
    beforeDestroy() {
        this.clearIntervals();
    }
};
</script>
<style lang="scss" scoped>
.fdf-round {
    height: 100vh;
    .fdf-img {
        height: 155px;
        width: 202px;
        @include themify($themes) {
            background: themed('err4') no-repeat center / contain;
        }
    }
    h3 {
        font-size: 17px;
        color: #111626;
        padding-top: 70px;
        font-weight: 600;
    }
    p {
        font-size: 13px;
        color: #858b9c;
        padding: 10px 0 20px;
    }
    .refresh-btn {
        @include themify($themes) {
            background: themed('line-btn-bg');
        }
        border-radius: 22px;
        width: 140px;
        height: 44px;
        font-size: 15px;
        color: #ffffff;
    }
}
</style>
