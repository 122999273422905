import api, { setPushUrl, isNewVersion } from "@/api/api"
import moduleBase from "../common/moduleBase"
import session from '@/utils/session'
import store from '../index'
import router from '../../router'

export const L = (v) => {
    return (v+'').length
}
const config = {
    state: {
        interVal: '',
        user: {}, //用户信息
        sellType: null,
        siteConfig: {},
        isLogin: false, // 是否第一次登录
        maintainText: {}, // 维护信息
        userWin: 0,
        changePWAccount: null,
        theme: 1,
    },
    actions: {
        changeTheme({ commit, state }, payload) {
            commit({
                type: "set",
                payload: {
                    theme: payload,
                }
            })
        },
        saveTheme({ commit, state }, payload) {
            session.setStorage('theme', payload)
            commit({
                type: "set",
                payload: {
                    theme: payload,
                }
            })
        },
        clearUserWin ({ commit, state }) {
            if (state.interVal) {
                clearInterval(state.interVal)
            }
        },
        // 获取用户今日输赢
        async getUserWin ({ commit,state }) {
            const res = await api.get("/getWinLose")
            commit({
                type: "set",
                payload: {
                    userWin: res.winLose,
                    user: {
                        ...state.user,
                        creditRemain: res.creditRemain,
                        creditTotal: res.creditTotal
                    },
                }
            })
        },
        // 获取维护信息
        getMaintainText ({ commit }, payload) {
            sessionStorage.setItem('maintainText', payload)
            commit({
                type: "set",
                payload: {
                    maintainText: payload,
                }
            })
        },
        // 是否是登录进入,点击完改完false
        getIsLogin ({ commit }, payload) {
            commit({
                type: "set",
                payload: {
                    isLogin: payload,
                }
            })
        },
        async login ({ commit, dispatch }, payload) {
            const res = await api.post("/login", payload)
            if (res) {
                dispatch({
                    type: "setCookieUserInfo",
                    payload: res
                })
                sessionStorage.removeItem('fastPrice')
                store.dispatch('socket/closeSocket')
                if (!res.isFirst) {
                    sessionStorage.setItem("user", JSON.stringify(res))
                    session.setStorage('theme', res.themeType)
                    await commit({
                        type: "set",
                        payload: {
                            user: res,
                        }
                    })
                }
            }
            return res
        },
        async getSiteConfig ({ commit }) {
            const res = await api.get("/config/getSiteConfig")
            if (res.pushUrl) {
                setPushUrl(res.pushUrl)
            }
            const { stomp } = store.state.socket
            if ((!stomp || !Object.keys(stomp).length) && store.state.user.user && store.state.user.user.sellType) {
                store.dispatch('socket/initConnect')
            }
            if (res.directorName) {
                document.title = `${res.directorName}会员端`
            } else {
                document.title = `会员端`
            }
            if (res.icon) {
                document.querySelector('link[rel="icon"]').href = res.icon
            }
            commit({
                type: "set",
                payload: {
                    siteConfig: res,
                }
            })
        },
        async logout ({ dispatch, state }) {
            const res = await api.post("/logout/1")
            await dispatch({
                type: "clearnUserInfo"
            })
            sessionStorage.removeItem('fastPrice')
            store.dispatch('socket/closeSocket')
            store.dispatch('socket/clearOneTime')
            return res
        },
        async getPlayerDetail ({ commit, dispatch, state }, status) {
            const res = await api.get("/info")
            const info = JSON.parse(sessionStorage.getItem('user'))
            const id = info ? info.playerId : ''
            const result = id ? { ...res, playerId: id, isAceptOdds: info.isAceptOdds, isForbidden: info.isForbidden, themeType: info.themeType} : res
            if (router.history.current && router.history.current.meta && router.history.current.meta.isForbidden && info.isForbidden) {
                router.replace('/reports')
            }
            sessionStorage.setItem("user", JSON.stringify(result))
            store.dispatch('user/saveTheme', session.getStorage('theme'))
            await commit({
                type: "set",
                payload: {
                    user: result,
                }
            })
            dispatch({
                type: "getUserWin"
            })
            if (!status) {
                isNewVersion()
                commit({
                    type: "set",
                    payload: {
                        interVal: setInterval(() => {
                            dispatch({
                                type: "getUserWin"
                            })
                        }, 10000)
                    }
                })
            }
            return res
        }
    }
}

export default {
    namespaced: true,
    ...moduleBase(config)
}