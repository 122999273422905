export function deepClone (source) {
    if (!source && typeof source !== 'object') {
        throw new Error('error arguments', 'deepClone')
    }
    const targetObj = source.constructor === Array ? [] : {}
    Object.keys(source).forEach(keys => {
        if (source[keys] && typeof source[keys] === 'object') {
            targetObj[keys] = deepClone(source[keys])
        } else {
            targetObj[keys] = source[keys]
        }
    })
    return targetObj
}

/**
 * 阿拉伯数字转中文数字
 * @param {Integer} num 
 */
export function NumToChinese (num, str = {}) {
    if (!num && num !== 0) {
        return 0
    }
    if (!/^\d*(\.\d*)?$/.test(num)) {
        return
    }
    num = num - 0
    let AA = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九")
    let BB = new Array("", "十", "百", "千", "万", "亿", "点", "")
    let a = ("" + num).replace(/(^0*)/g, "").split("."),
        k = 0,
        re = ""
    for (let i = a[0].length - 1; i >= 0; i--) {
        switch (k) {
            case 0:
                re = BB[7] + re
                break
            case 4:
                if (!new RegExp("0{4}\\d{" + (a[0].length - i - 1) + "}$").test(a[0]))
                    re = BB[4] + re
                break
            case 8:
                re = BB[5] + re
                BB[7] = BB[5]
                k = 0
                break
        }
        if (k % 4 === 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) === 0) re = AA[0] + re
        if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re
        k++
    }
    if (a.length > 1) {
        re += BB[6]
        for (let i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)]
    }
    if (re === "一十") {
        re = "十"
    }
    let vals = (str.first || "") + re + (str.tail || "")
    return vals || 0
}

// 获取当前日期的前后n天的日期
export const getDateStr = (AddDayCount = 0) => {
    const dd = new Date()
    dd.setDate(dd.getDate() + AddDayCount)
    const y = dd.getFullYear()
    const m = dd.getMonth() + 1
    const d = dd.getDate()
    return Date.parse(new Date(`${y}-${m < 10 ? '0' + +m : m}-${d}`))
}