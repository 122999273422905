import { deepClone } from "@/utils/util"
import Cookies from "js-cookie"

const baseConfig = {
    state: {
    },
    actions: {
        async setCookieUserInfo ({ commit }, { payload }) {
            const token_info = await Cookies.get("token_info")
                ? JSON.parse(Cookies.get("token_info"))
                : {}
            const newpayload = Object.assign({}, payload)
            const data = JSON.parse(JSON.stringify({ ...token_info, ...(newpayload || {}) }))
            await sessionStorage.setItem("access_token", data.token)

            let hostname = window.location.hostname.split(".")
            if (hostname.length === 4) {
                hostname.shift()
            }
            await Cookies.set(
                "token_info",
                JSON.stringify(data),
                {
                    expires: 7,
                    domain: hostname.join("."),
                }
            )
            return data
        },
        async clearnUserInfo ({ commit }) {
            let hostname = window.location.hostname.split(".")
            if (hostname.length === 4) {
                hostname.shift()
            }
            await Cookies.remove("token_info", {
                expires: 7,
                domain: hostname.join(".")
            })
            await sessionStorage.removeItem("access_token")
            await sessionStorage.removeItem("user")
            await commit({
                type: "set",
                payload: {
                    user: {}
                }
            })
        }
    },
    mutations: {
        set (state, { payload }) {
            if (!payload || !payload instanceof Object) {
                throw new Error('error arguments: mutations payload need a type Object')
            }
            Object.keys(payload).forEach(keys => {
                state[keys] = payload[keys]
            })
        }
    }
}

export default function moduleBase (obj = {}) {
    const o = deepClone(obj)
    Object.keys(baseConfig).map(_ => {
        o[_] = { ...baseConfig[_], ...o[_] }
    })
    return o
}