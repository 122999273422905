export const generateNum = (count, max, min) => {
    const result = []
    const resultKey = {}
    while (result.length < count) {
        let num = Math.random() * (max - min + 1) + min
        if (num > max) num = max
        if (!resultKey[num]) {
            result.push(num)
            resultKey[num] = true
        }
    }
    return result
}
import {F} from '@/config/hk-caption'

// 阶乘 
function factorial(m, n) {
    let num = 1
    let count = 0
    for (let i = m; i > 0; i--) {
        if (count === n) {
            // 当循环次数等于指定的相乘个数时，即跳出for循环
            break
        }
        num = num * i
        count++
    }
    return num
}

// 组合算法 注数
export const combineNum = (m, n) => {
    return Math.floor(factorial(m, n) / factorial(n, n))
}

// like n串m
export const combineBetArr = (arr, m) => {
    const result = [];
    (function f(j, e, h) {
        if (h === 0) {
            return result.push(j)
        }
        for (let i = 0, g = e.length; i <= g - h; i++) {
            f(j.concat(e[i]), e.slice(i + 1), h - 1)
        }
    })([], arr, m)
    return result
}

export const numberToString = (arr) => arr.map((m) => 10 > m ? `0${m}` : `${m}`)


export const sleep = (timer) => {
    return new Promise((resolve) => setTimeout(resolve, timer))
}


/**
 * @name: 判断两个数组书序是否一致
 * @test: test font
 * @msg: 
 * @param {type} arr 
 * @return: arr
 */
export const isSortEqual = (arr1, arr2) => {
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i].id !== arr2[i].id) {
            return false
        }
    }
    return true

}

// 胆拖 算法
export const combineFiexNumber = (arrDan, arrTuo, length) => {
    // const maxLen = length - 1
    // if (arrDan.length > maxLen) {
    //     console.log('胆数 大约 maxLen' + maxLen)
    // }
    if (!arrDan.length) return []
    if (!arrTuo.length) return []
    const data = combineBetArr(arrTuo, length - arrDan.length)
    return data.map((m) => m.concat(arrDan))
}

// 对碰算法
export const combineDoubleNumber = (...chunks) => {
    const res = []
    const helper = function (chunkIndex, pre) {
        const chunk = chunks[chunkIndex]
        const isLast = chunkIndex === chunks.length - 1
        for (const val of chunk) {
            const cur = pre.concat(val)
            if (isLast) {
                res.push(cur)
            } else {
                helper(chunkIndex + 1, cur)
            }
        }
    }
    helper(0, [])
    const result = res.filter(item => isRepet(item))
    return result
}

// 判断重复
const isRepet = (arr) => {
    let hash = {}
    for(let i in arr) {
        let item = arr[i]
        if (hash[item.num]) {
            return false
        }
        hash[item.num] = true
    }
    return true
}

export const E = '3'
export const O = 'Z'
export const h = 'g'
export const S = 'h'
export const t = 'j'
export const A = 'k'
export const l = 'l'
export const P = 'z'
export const R = 'n'
export const K = 'm'
export const I = 'Q'
export const p = 'W'
export const u = 'E'
export const a = '7'
export const Y = '8'
export const B = '9'
export const Q = 'R'
export const L = 'T'
export const r = 'a'
export const s = 'f'

export const e = F([E,O,h,S,t,A,l,P,R,K,I,p,u,a,Y,B,Q,L,r,s])