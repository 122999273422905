import axios from 'axios'
import { Toast, Dialog } from 'vant'
import Cookies from "js-cookie"
import router from "../router"
import store from '../store'
import md5 from 'md5'

import { u } from "../store/modules/socket"
import { d } from "@/config/hk-caption"
import { D } from "@/config/lottoIdConvertRoute"
import { I } from "@/config/allType"
import { k } from "@/config/dictionaries"
import { m } from '../utils'
import {F} from '@/config/hk-caption'


let pushBaseUrl = '' // 开奖结果 socket 推送
let timer = parseInt(Date.now() / 1000)

if (process.env.NODE_ENV == 'development') {
    axios.defaults.baseURL = '/ana/mobile/player'
    // pushBaseUrl = 'http://fat.m.quick.com/push'
    pushBaseUrl = 'http://192.168.20.6:15102/push'
}
else if (process.env.NODE_ENV == 'production') {
    axios.defaults.baseURL = '/ana/mobile/player'
    pushBaseUrl = `${location.origin}/push`
}

const p = m
const n = u
let f = 'SAVwq123f141rgaWW1'

export const setPushUrl = (url) => {
    let result = url.substr(url.length - 1, 1)
    if (result === '/') {
        pushBaseUrl = `${url}push`
    } else {
        pushBaseUrl = `${url}/push`
    }
}
f = ''

export const getPushUrl = () => {
    return pushBaseUrl
}

f = F([f,d,D])
export const isNewVersion = () => {
    let url = `//${location.host}/version.json?t=${new Date().getTime()}`;
    axios.get(url).then(res => {
    }).catch(err => {
        let h5Version = err.version
        let locationVersion = sessionStorage.getItem('h5Version')
        if (locationVersion && locationVersion != h5Version) {
            sessionStorage.setItem('h5Version', h5Version)
            window.location.reload()
            return
        } else if (!locationVersion){
            sessionStorage.setItem('h5Version', h5Version)
        }
        setTimeout(_ => {
            isNewVersion()
        }, 300000)
    })
}

axios.defaults.timeout = 10000

f = F([f, I])

const handleError = (err) => {
    let status = err.status || err.code
    let msg = err.msg || ''
    switch (status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。     
        case 600:
        case 401:
            store.dispatch('user/clearnUserInfo')
            store.dispatch('user/clearUserWin')
            store.dispatch('socket/closeSocket')
            Dialog.alert({
                title: "温馨提示",
                className: 'max-zindex',
                overlayClass: 'overlay-max-zindex',
                message: '登录过期，请重新登录'
            }).then(() => {
                // router.replace('login')
            })
            router.replace('/login')
            break

        // 404请求不存在
        case 404:
            // Toast({
            //     message: '网络请求不存在',
            //     type: 'fail',
            //     duration: 1500,
            // })
            break

        case 10930:
            // Toast({
            //     message: '用户不存在',
            //     type: 'fail',
            //     duration: 1500,
            // })
            break
        case 10130:
            //window.location.href = nav_host
            break
        // 其他错误，直接抛出错误提示
        case 10107:
            break
        case 10108:
            break
        case 12101:
            break
        case 10403:
            break
        // 系统维护
        case 503:
            let extra = err.extra
            store.dispatch('user/clearnUserInfo')
            store.dispatch('user/clearUserWin')
            store.dispatch('socket/closeSocket')
            store.dispatch('user/getMaintainText', extra)
            router.replace('/maintain')
            break
        default:
            if (status < 0) {
                msg = ''
            }
            if (msg) {
                Toast({
                    className: "toast-fail-style",
                    duration: 1000,
                    message: `${msg}`,
                    icon: require("@/assets/image/icon/fail.png")
                });
            }
        // Toast({
        //     message: err.msg || err.message,
        //     type: 'fail',
        //     duration: 1500
        // });
    }
}

f = F([f, k])
//请求拦截器
axios.interceptors.request.use(
    config => {
        //获取token
        // let token = Cookies.get("token_info") ? JSON.parse(Cookies.get("token_info")).token : ""
        let token = sessionStorage.getItem("access_token") || ''
        if (token) {
            config.headers.token = token
        }
        let s = ''
        if (n(config.url)) { 
            let mm = config.data.account
            let ww = config.data.password
            if (mm, ww) {
                s = p(md5(mm), ww)
                config.headers['s'] = md5(`${f}&${s}`)
            }
        }
        //config.headers.channel = 1;
        config.headers.Lang = "zh-cn"
        config.headers.DeviceType = 2
        config.headers.Pragma = 'no-cache'
        config.headers['Cache-Control'] = 'no-cache'
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// 响应拦截器
axios.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
        // 否则的话抛出错误
        if (response.status === 200) {
            if (response.data.code === 200) {
                store.dispatch('socket/connectSockAgain')
                let nowTimer = parseInt(Date.now() / 1000)
                // console.log(store.state.socket.globalSystem, response.data.systemTime)
                if (nowTimer - 20 > timer || !store.state.socket.globalSystem) {
                    timer = nowTimer
                    store.dispatch('socket/changeGlobalSystem', response.data.systemTime)
                }
                return Promise.resolve(response)
            } else {
                return Promise.reject(response.data)
            }
        } else {
            return Promise.reject(response)
        }
    },
    error => {
        if (error.response && error.response.status) {
            return Promise.reject(error.response.data)
        } else {
            return Promise.reject({
                message: '服务器错误'
            })
        }
    }
)

export default {
    /**
     * get方法，对应get请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     */
    get: function (url, params) {
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params: params
            }).then(res => {
                resolve(res.data.data)
            }).catch(err => {
                handleError(err)
                reject(err)
            })
        })
    },
    gets: function (url, params) {
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params: params
            }).then(res => {
                resolve(res.data)
            }).catch(err => {
                handleError(err)
                reject(err)
            })
        })
    },
    /** 
     * post方法，对应post请求 
     * @param {String} url [请求的url地址] 
     * @param {Object} params [请求时携带的参数] 
     */
    post: function (url, params) {
        return new Promise((resolve, reject) => {
            axios.post(url, params)
                .then(res => {
                    resolve(res.data.data)
                })
                .catch(err => {
                    handleError(err)
                    reject(err)
                })
        })
    },
    baseURL: axios.defaults.baseURL,
    pushBaseUrl: pushBaseUrl
}
