import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import Router from "vue-router"
import store from "./store"
import api from "./api/api"
import "amfe-flexible"
import Vant from "vant"
import * as filters from './filters/index'
import "vant/lib/icon/local.css"
import session from './utils/session'
import "./style/icon.scss"
import "./style/ball.css"
import "./style/common.scss"
Vue.use(Vant)
Vue.prototype.$axios = api
Vue.config.productionTip = false

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}

Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})


router.beforeEach(async (to, from, next) => {
    if (to.name !== "login" && to.name !== "maintain" && to.name !== "appAgree" && to.name !== 'appRules') { // 判断该路由是否需要登录权限  && to.path.indexOf('/draw') === -1
        if (sessionStorage.getItem("access_token")) { // 判断当前的token是否存在
            if (!store.state.user || !store.state.user.user || !store.state.user.user.sellType || JSON.stringify(store.state.user.user) === "{}") {
                try {
                    store.dispatch('user/getPlayerDetail')
                    const { stomp } = store.state.socket
                    if ((!stomp || !Object.keys(stomp).length) && store.state.user.siteConfig && JSON.stringify(store.state.user.siteConfig) !== "{}") {
                        store.dispatch('socket/initConnect')
                    }
                    await store.dispatch('lottery/getLotteryList')
                    next()
                } catch (error) {
                    next()
                }
            } else {
                if (to.meta && to.meta.isForbidden && store.state.user.user.isForbidden) {
                    next('/reports')
                    return
                }
                next()
            }
        } else {
            next({ path: '/login' })
        }
    } else {
        next()
    }
})
router.afterEach(_ => {
    window.scroll(0, 0)
})
window.addEventListener('beforeunload', _ => {
    window.scrollTo(0, 0)
})
// router.push(route).catch(err => {
//     console.log("报错",err);

// })
new Vue({
    data: {
        Bus: new Vue()
    },
    router,
    store,
    render: h => h(App)
}).$mount("#app")
