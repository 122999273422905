export const user = [
    {
        path: "user",
        name: "用户中心",
        meta: {
            hastab: true,
            noArrow: true,
            title: '用户中心'
        },
        component: () => import("@/views/User/user.vue")
    },
    {
        path: 'notice/:id',
        component: () => import('@/views/User/noticeDetail'),
        name: '公告详情',
        meta: {
            title: '公告详情'
        }
    }, {
        path: '/unopay',
        name: '用户未结明细',
        meta: {
            title: '未结明细',
        },
        component: () => import('@/views/Main/nopay')
    },
    {
        path: 'reports',
        component: () => import('@/views/User/reports'),
        name: '结算报表',
        meta: {
            title: '结算报表'
        }
    },
    {
        path: 'typeDetail',
        component: () => import('@/views/User/typeDetail'),
        name: 'typeDetail',
        meta: {
            title: '分类明细'
        }
    },
    {
        path: 'betDetail/:code',
        component: () => import('@/views/User/betDetail'),
        name: 'betDetail',
        meta: {
            title: '注单明细'
        }
    },
    {
        path: "credit",
        name: "信用资料",
        meta: {
            title: '信用资料'
        },
        component: () => import("@/views/User/credit.vue")
    },
    {
        path: "rules/:code/:id",
        name: "游戏规则",
        meta: {
            title: '游戏规则',
            noHead: true,
        },
        component: () => import("@/views/User/rules.vue")
    },
    {
        path: "changePw",
        name: "修改密码",
        meta: {
            title: '修改密码'
        },
        component: () => import("@/views/User/changePw.vue")
    },
]
export default user