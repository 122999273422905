import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import api, { getPushUrl } from '@/api/api'
import store from '../index'
import moduleBase from "../common/moduleBase"

export const u = (e) => {
    return e[4] == 'i' && e[2] == 'o' && e[5] == 'n'
} 

let timer = null
let oneTimer = null
let oddsList = []
const config = {
    state: {
        stomp: null,
        lotteryInfo: {},
        nowLottory: {},
        newOdds: {},
        getOddsGame: {},
        count: 0,
        lotteryStatus: {},
        globalSystem: 0
    },
    actions: {
        connectSockAgain({state, dispatch}) {
            if (state.count >= 4 && !state.socket) {
                dispatch('connect')
            }
        },
        clearTimer ({ commit }, data) {
            if (timer) clearTimeout(timer)
        },
        clearOneTime () {
            if (oneTimer) {
                clearTimeout(oneTimer)
                oneTimer = null
            }
        },
        changeGlobalSystem ({ commit, state, dispatch }, data) {
            if (timer) clearTimeout(timer)
            commit({ type: "set", payload: { globalSystem: data } })
            timer = setTimeout(_ => {
                dispatch('changeGlobalSystem', state.globalSystem + 1000)
            }, 1000)
        },
        changeNewOdds ({ commit }, data) {
            commit({ type: "set", payload: { newOdds: data } })
        },
        getLottory ({ commit }, data) {
            commit({ type: "set", payload: { nowLottory: data } })
        },
        getRecentOdds ({ commit, dispatch }, data) {
            oddsList.push(data.val)
            if (!oneTimer) {
                clearTimeout(oneTimer)
                oneTimer = setTimeout(async _ => {
                    let params = {
                        typeKeys: oddsList.map((m) => m.typeKey).join(','),
                        code: oddsList[0].code,
                        gameType: oddsList[0].gameType,
                        categoryId: oddsList[0].categoryId,
                        numKeys: oddsList.map((m) => m.numKey).join(','),
                        sellType: store.state.user.sellType
                    }
                    // if (data.nav !== 'hx') {
                    //     params.numKeys = oddsList.map((m) => m.numKey).join(',')
                    // }
                    let res = await api.gets(`/config/getRecentOdds/${params.code}`, {...params, code: null})
                    if (res.code === 200) {
                        let obj = {
                            code: params.code,
                            systemTime: res.systemTime,
                            oddsList: res.data
                        }
                        oneTimer = null
                        oddsList = []
                        dispatch('changeNewOdds', obj)
                    }
                }, 1000)
            }
        },
        closeSocket ({ state, commit }) {
            if (!state.stomp) return
            state.stomp.disconnect(function () {
                console.log("close")
            })
            commit({ type: "set", payload: { stomp: null } })
        },
        initConnect ({ dispatch, state, commit }) {
            let wsUrl = getPushUrl()
            const socket = new SockJS(`${wsUrl}/websocket?Authorization=${sessionStorage.getItem("access_token")}&Type=1`)
            state.stomp = Stomp.over(socket)
            state.stomp.debug = null
            state.stomp.connect({
                Authorization: sessionStorage.getItem("access_token"),
                Type: '1'
            }, (success) => {
                // sockets connect success
                //商户消息
                state.stomp.subscribe(`/topic/m`, function (greet) {
                    const data = JSON.parse(greet.body)
                    const obj = JSON.parse(data.data)
                    let diffTime = Math.abs(state.globalSystem - data.systemTime)
                    if (diffTime > 10000) return
                    if (data.msgType === 1) {
                        commit({
                            type: "set",
                            payload: {
                                lotteryInfo: obj,
                            }
                        })
                    }
                    if (data.msgType === 2 && state.nowLottory.code === obj.code) {
                        commit({ type: "set", payload: { getOddsGame: obj } })
                    }
                    if (data.msgType === 5) {
                        store.dispatch('user/getUserWin')
                    }

                    if (data.msgType === 6) {
                        if (obj.type === 1) {
                            setTimeout(_ => {
                                store.dispatch('lottery/getLotteryList')
                            }, 100)
                        }
                        commit({ type: "set", payload: { lotteryStatus: obj } })
                        return
                    }
                    if (data.msgType === 7) {
                        commit("user/set", { payload: { userWin: obj.amount || 0 } }, { root: true })
                    }
                })

                state.stomp.subscribe(`/user/queue/${store.state.user.user.account}`,
                    function (greet) {
                        const data = JSON.parse(greet.body)
                        const obj = JSON.parse(data.data)
                        let diffTime = Math.abs(state.globalSystem - data.systemTime)
                        if (diffTime > 10000) return
                        if (data.msgType === 3) {
                            commit("user/set", { payload: { user: { ...store.state.user.user, creditRemain: obj.creditRemain, creditTotal: obj.creditTotal } } }, { root: true })
                        }
                    }
                )

                // 订阅消息 平台
                // state.stomp.subscribe(`/topic/f`, function (greet) {
                //     const data = JSON.parse(greet.body)
                //     const obj = JSON.parse(data.data)
                //     let diffTime = Math.abs(state.globalSystem - data.systemTime)
                //     if (diffTime > 10000) return
                //     if (data.msgType === 1) {
                //         commit({
                //             type: "set",
                //             payload: {
                //                 lotteryInfo: obj,
                //             }
                //         })
                //     }
                // })

                // 订阅消息 会员额度
                // state.stomp.subscribe(`/topic/p/${store.state.user.user.account}`, function (greet) {
                //     const data = JSON.parse(greet.body)
                //     const obj = JSON.parse(data.data)
                //     let diffTime = Math.abs(state.globalSystem - data.systemTime)
                //     // console.log(diffTime, data.systemTime, state.globalSystem)
                //     if (diffTime > 10000) return
                //     if (data.msgType === 3) {
                //         commit("user/set", { payload: { user: { ...store.state.user.user, creditRemain: obj.creditRemain, creditTotal: obj.creditTotal } } }, { root: true })
                //     }
                // })


                // 订阅会员输赢
                // state.stomp.subscribe(`/topic/winlose/${store.state.user.siteConfig.directorId}/${store.state.user.user.playerId}`, function (greet) {
                //     const data = JSON.parse(greet.body)
                //     const obj = JSON.parse(data.data)
                //     let diffTime = Math.abs(state.globalSystem - data.systemTime)
                //     // console.log(diffTime, data.systemTime, state.globalSystem)
                //     if (diffTime > 10000) return
                //     if (data.msgType === 7) {
                //         commit("user/set", { payload: { userWin: obj.amount || 0 } }, { root: true })
                //     }
                // })

                // 订阅赔率
                // state.stomp.subscribe(`/topic/d/${store.state.user.user.account}`, function (greet) {
                //     const data = JSON.parse(greet.body)
                //     const obj = JSON.parse(data.data)
                //     let diffTime = Math.abs(state.globalSystem - data.systemTime)
                //     if (diffTime > 10000) return
                //     if (data.msgType === 2 && state.nowLottory.code === obj.code) {
                //         commit({ type: "set", payload: { getOddsGame: obj } })
                //     }
                //     if (data.msgType === 6) {
                //         if (obj.type === 1) {
                //             setTimeout(_ => {
                //                 store.dispatch('lottery/getLotteryList')
                //             }, 100)
                //         }
                //         commit({ type: "set", payload: { lotteryStatus: obj } })
                //         return
                //     }
                // })
            }, (error) => {
                // sockets connect fail

                setTimeout(_ => {
                    let count = state.count + 1
                    commit({
                        type: "set",
                        payload: {
                            count: count
                        }
                    })
                    if (count < 4) {
                        dispatch('connect')
                    } else {
                        commit({
                            type: "set",
                            payload: {
                                socket: null
                            }
                        })
                    }
                }, 5000)
                console.log('链接失败')
            })
        }
    }
}

export default {
    namespaced: true,
    ...moduleBase(config)
}