export const gameType = {
    10001: {
        NUM: "1-5球",
        NUMDX: "1-5球大小",
        NUMDS: "1-5球单双",
        HEDX: "总和大小",
        HEDS: "总和单双",
        NUMLH: "龙虎和",
        QZH: "前中后三",
    },
    10002: {
        NUM: "1-8球",
        NUMDX: "1-8球大小",
        NUMDS: "1-8球单双",
        NUMWS: "1-8球尾数",
        NUMHS: "1-8球合数",
        NUMFW: "1-8球方位",
        NUMZFB: "1-8球中发白",
        NUMLH: "龙虎",
        HEDX: "总和大小",
        HEDS: "总和单双",
        HEWS: "总和尾数",
    },
    10003: {
        NUM: "1-10名",
        NUMDX: "1-10大小",
        NUMDS: "1-10单双",
        NUMLH: "1-10龙虎",
        HE: "冠亚和",
        HEDX: "冠亚和大小",
        HEDS: "冠亚和单双",
    },
    10004: {
        NUM: "1-5球",
        NUMDX: "1-5球大小",
        NUMDS: "1-5球单双",
        ZY: "中一",
        NUMLH: "龙虎",
        HEDX: "总和大小",
        HEDS: "总和单双",
        HEWS: "总和尾数"
    },
    10005: {
        TMA: "特码A",
        TMB: "特码B",
        TMSM: "特码双面",
        TMBS: "特码波色",
        ZM: "正码A",
        ZMB: "正码B",
        ZMSM: "正码双面",
        ZMBS: "正码波色",
        ZHSM: "总和双面",
        ZMT: "正码特",
        LM3QZ: "三全中",
        LM3Z2: "三中二",
        LM2QZ: "二全中",
        LM2ZT: "二中特",
        LMTC: "特串",
        LM4QZ: "四全中",
        TX: "特肖",
        BB: "半波",
        HX: "合特肖",
        HX_HX2: "合特肖_二肖",
        HX_HX3: "合特肖_三肖",
        HX_HX4: "合特肖_四肖",
        HX_HX5: "合特肖_五肖",
        HX_HX6: "合特肖_六肖",
        HX_HX7: "合特肖_七肖",
        YXZ: "一肖中",
        YXBZ: "一肖不中",
        WSZ: "尾数中",
        WSBZ: "尾数不中",
        LX2: "二肖连中",
        LX3: "三肖连中",
        LX4: "四肖连中",
        LX5: "五肖连中",
        LW2: "二尾连中",
        LW3: "三尾连中",
        LW4: "四尾连中",
        LW5: "五尾连中",
        '5BZ': "五不中",
        '6BZ': "六不中",
        '7BZ': "七不中",
        '8BZ': "八不中",
        '9BZ': "九不中",
        '10BZ': "十不中",
        '4Z1': "四中一",
        '5Z1': "五中一",
        '6Z1': "六中一",
        '7Z1': "七中一",
        '8Z1': "八中一",
        '9Z1': "九中一",
        '10Z1': "十中一",
        LXBZ2: "二肖连不中",
        LXBZ3: "三肖连不中",
        LXBZ4: "四肖连不中",
        LXBZ5: "五肖连不中",
        LWBZ2: "二尾连不中",
        LWBZ3: "三尾连不中",
        LWBZ4: "四尾连不中",
        LWBZ5: "五尾连不中",
        RZ1: "一粒任中",
        RZ2: "二粒任中",
        RZ3: "三粒任中",
        RZ4: "四粒任中",
        RZ5: "五粒任中",
        RZ6: "六粒任中",
        WL: "尾数量",
        XL: "生肖量"
    },
    10006: {
        HE: "总和810",
        HEDX: "总和大小",
        HEDS: "总和单双",
        HEDXDS: "总和组合",
        QHH: "前后和",
        DSH: "单双和",
        WX: "五行",
        NUM: "正码",
    },
    10007: {
        HEDX: "大小",
        SJ: "三军",
        DS: "点数",
        WS: "围骰",
        QS: "全骰",
        CP: "长牌",
        DP: "短牌",
    },
}

export const l = (c) => {
    return Math.floor(Math.random() * c.length)
}

export const k = 'VZgh6CyuAxioBYznXU4tR7Ic'