/*
 * @Descripttion: 两面长龙字典
 */

const getList = (list) => {
    let obj = []
    for (let i in list) {
        let arr = list[i]
        arr.forEach(item => {
            obj = obj.concat(item.list)
        })
    }
    return obj.filter(item => !item.isEmpty)
}

import {F} from '@/config/hk-caption'
const list = (arr) => {
    let vals = {}
    arr.forEach(item => {
        vals[`${item.typeKey}_${item.numKey}`] = `${item.tradeType}-${item.num}`
    })
    return vals
}

const onlyList = (arr) => {
    let vals = {}
    arr.forEach(item => {
        vals[`${item.typeKey}`] = `${item.tradeType}`
    })
    return vals
}

const ssc = () => {
    const chineseBasicName = ['大', '小', '单', '双', '龙', '虎']
    const chineseNumberName = ['第一球', '第二球', '第三球', '第四球', '第五球']
    const tradeTypeNames = ['大小', '大小', '单双', '单双', '龙虎', '龙虎', '龙虎']
    const playerTypeName = ['DX', 'DX', 'DS', 'DS', 'LH', 'LH', 'LH']
    const playNumName = ['D', 'X', 'D', 'S', 'L', 'H', 'HE']
    const beforeNames = ['豹子', '顺子', '对子', '半顺', '杂六']
    const beforeENames = ['BZ', 'SZ', 'DZ', 'BS', 'ZL']

    // 第几球的 大小单双龙虎
    const combineDXLH = (num, len = 6) => {
        return Array.from({ length: num <= 5 ? len : 4 }, (v, i) => {
            return {
                tradeType: `${chineseNumberName[num - 1]}`,
                typeKey: `NUM${num}${playerTypeName[i]}`,
                numKey: `${playNumName[i]}`,
                num: `${chineseBasicName[i]}`,
            }
        })
    }

    // 总和龙虎
    const combineHe = (len) => {
        return Array.from({ length: len }, (v, i) => {
            if (i > 6) {
                return {
                    isEmpty: true,
                    width: 'width-4'
                }
            }
            return {
                tradeType: i > 3 ? `${tradeTypeNames[i]}` : `总和`,
                typeKey: i > 3 ? `NUMLH` : `HE${playerTypeName[i]}`,
                numKey: playNumName[i],
                num: i > 5 ? '和' : `${chineseBasicName[i]}`,
            }
        })
    }

    // {num1 1}
    const combineNum = (num, len = 10) => {
        return Array.from({ length: len }, (v, i) => {
            return {
                tradeType: `${chineseNumberName[num - 1]}`,
                typeKey: `NUM${num}`,
                numKey: i,
                num: i,
            }
        })
    }

    // 前中后 三
    const combineSz = (num) => {
        return Array.from({ length: num }, (v, i) => {
            return {
                list: Array.from({ length: 5 }, (v, ii) => {
                    return {
                        tradeType: `${['前', '中', '后'][i]}三`,
                        typeKey: `${['Q', 'Z', 'H'][i]}S`,
                        numKey: beforeENames[ii],
                        num: beforeNames[ii],
                    }
                })
            }
        })
    }

    const oneFiveSize = Array.from({ length: 5 }, (v, i) => {
        return {
            list: combineDXLH(i + 1, 4)
        }
    })

    const singleNumData = Array.from({ length: 5 }, (v, i) => {
        return {
            list: combineNum(i + 1)
        }
    })

    const ballSizeOddsData = (num) => {
        return [
            {
                list: combineNum(num + 1), // 1-9
            },
            {
                list: combineDXLH(num + 1, 4), // 大小单双'
            },
            {
                list: combineHe(8)
            },
            ...combineSz(3)
        ]
    }

    return {
        // 两面
        db: oneFiveSize.concat({
            list: combineHe(8)
        }),
        odd: singleNumData,
        combine: combineSz(3),
        one: ballSizeOddsData(0),
        two: ballSizeOddsData(1),
        three: ballSizeOddsData(2),
        four: ballSizeOddsData(3),
        five: ballSizeOddsData(4),
    }
}
export const Y = (a,b) => {
    return [a, a, b, b]
}

export const I = 'D5eE23TOr'

export const w = '1'
export const s = '1'
export const T = '2'
export const G = 'Z'
export const l = 'Z'
export const g = 'S'
export const p = '1'
export const P = 'Y'
export const i = 'U'
export const j = 'I'
export const B = 'O'
export const U = 'P'
export const K = 'A'
export const C = 'S'
export const q = 'D'
export const X = 'x'
export const S = 'c'
export const D = 'v'


export const c = F([w,s,T,G,l,g,p,P,i,j,B,U,K,C,q,X,S,D])
// 快乐10
const kl10 = () => {
    const playNames = ['大', '小', '单', '双']
    const weiNames = ['尾大', '尾小']
    const playerTypeName = ['DX', 'DX', 'DS', 'DS', 'WS', 'WS', 'HS', 'HS', 'LH', 'LH']
    const chineseBallEightName = ['第一球', '第二球', '第三球', '第四球', '第五球', '第六球', '第七球', '第八球']
    const playNumName = ['D', 'X', 'D', 'S', 'D', 'X', 'D', 'S', 'L', 'H']
    const chineseBasicName = ['大', '小', '单', '双', '尾大', '尾小']
    const dbSortData = Array.from({ length: 8 }, (v, i) => {
        return {
            list: Array.from({ length: i < 4 ? 10 : 10 }, (vv, ii) => {
                if (i > 3 && ii > 7) {
                    return {
                        isEmpty: true,
                        width: 'width-5'
                    }
                }
                return {
                    tradeType: `${chineseBallEightName[i]}`,
                    typeKey: `NUM${i + 1}${playerTypeName[ii]}`,
                    num: `${[...playNames, ...weiNames, '合单', '合双', '龙', '虎'][ii]}`,
                    numKey: playNumName[ii],
                }
            })
        }
    })

    const getValData = (i) => {
        return {
            list: Array.from({ length: 10 }, (vv, ii) => {
                if (i > 3 && ii > 7) {
                    return {
                        isEmpty: true,
                        width: 'width-5'
                    }
                }
                return {
                    tradeType: `${chineseBallEightName[i]}`,
                    typeKey: `NUM${i + 1}${playerTypeName[ii]}`,
                    num: `${[...playNames, ...weiNames, '合单', '合双', '龙', '虎'][ii]}`,
                    numKey: playNumName[ii],
                }
            })
        }
    }

    const numberBalls = (num, len = 20) => {
        return {
            list: Array.from({ length: len }, (v, i) => {
                return {
                    tradeType: `${chineseBallEightName[num]}`,
                    typeKey: `NUM${num + 1}`,
                    num: i + 1,
                    numKey: i + 1,
                }
            })
        }
    }

    const combineHe = (len) => {
        return Array.from({ length: len }, (v, i) => {
            if (i > 5) {
                return {
                    isEmpty: true,
                    width: 'width-4'
                }
            }
            return {
                tradeType: `总和`,
                typeKey: `HE${playerTypeName[i]}`,
                numKey: playNumName[i],
                num: `${chineseBasicName[i]}`,
            }
        })
    }

    return {
        // 两面
        db: [
            { list: combineHe(8) },
            ...dbSortData
        ],
        ball1: [numberBalls(0), getValData(0)],
        ball2: [numberBalls(1), getValData(1)],
        ball3: [numberBalls(2), getValData(2)],
        ball4: [numberBalls(3), getValData(3)],
        ball5: [numberBalls(4), getValData(4)],
        ball6: [numberBalls(5), getValData(5)],
        ball7: [numberBalls(6), getValData(6)],
        ball8: [numberBalls(7), getValData(7)],
    }
}

const k3 = () => {
    const sjData = (num) => {
        return Array.from({ length: num }, (v, i) => {
            return {
                tradeType: i < 6 ? '三军' : '大小',
                typeKey: i < 6 ? `SJ` : 'HEDX',
                num: i < 6 ? i + 1 : ['大', '小'][i - 6],
                numKey: i < 6 ? i + 1 : ['D', 'X'][i - 6],
            }
        })
    }

    // 2. 围 全 晒
    const wsData = (num) => {
        return Array.from({ length: num }, (v, i) => {
            if (i > 6) {
                return {
                    isEmpty: true,
                    width: 'width-4'
                }
            }
            return {
                tradeType: i === 6 ? '全骰' : '围骰',
                typeKey: i === 6 ? 'QS' : `WS`,
                num: i === 6 ? '全骰' : `${i + 1}`.repeat(3),
                numKey: i === 6 ? 'QS' : `${i + 1}`.repeat(3),
            }
        })
    }

    // 3. 点数

    const dsData = (num) => {
        return Array.from({ length: num }, (v, i) => {
            if (i > 13) {
                return {
                    isEmpty: true,
                    width: 'width-5'
                }
            }
            return {
                tradeType: '点数',
                typeKey: `DS`,
                num: `${i + 4}`,
                numKey: `${i + 4}`,
            }
        })
    }

    const combineData = (arr, size) => {
        const result = []
        function combine (t, a, n) {
            if (n === 0) {
                result[result.length] = t
                return
            }
            const l = a.length - n
            for (let i = 0; i <= l; i++) {
                const b = t.slice()
                b.push(a[i])
                combine(b, a.slice(i + 1), n - 1)
            }
        }
        combine([], arr, size)
        return result
    }

    // 4. 长牌

    const cpData = (len) => {
        const nums = Array.from({ length: len }, (v, i) => i + 1)
        const dataArr = combineData(nums, 2) // 所有组合的二位数组
        return dataArr.map((v) => {
            return {
                tradeType: '长牌',
                typeKey: `CP`,
                num: v.join(''),
                numKey: v.join(''),
            }
        })
    }

    // 短牌

    const combineSome = (num) => {
        return Array.from({ length: num }, (v, i) => `${i + 1}`.repeat(2))
    }

    const dpData = (len) => {
        const data = combineSome(len)
        return data.map((v) => {
            return {
                tradeType: '短牌',
                typeKey: `DP`,
                num: v,
                numKey: v,
            }
        })
    }

    return {
        sj: [
            {
                list: sjData(8)
            },
            {
                list: dsData(15)
            },
        ],
        ws: [
            {
                list: wsData(8)
            },
        ],
        cp: [
            {
                list: cpData(6)
            },
            {
                list: dpData(6)
            },
        ],
    }
}

const kl8 = () => {
    const typeKeys = ['DX', 'DX', 'DS', 'DS', 'DXDS', 'DXDS', 'DXDS', 'DXDS', '']
    const tradeTypeName = ['总和', '总和', '总和', '总和', '总和', '总和', '总和', '总和', '总和810']
    const nums = ['大', '小', '单', '双', '大单', '大双', '小单', '小双', '总和810']
    const numKeys = ['D', 'X', 'D', 'S', 'DD', 'DS', 'XD', 'XS', '810']

    // 总和、比数、五行--------------------

    // 1. 总和、总和过关

    const allHeData = Array.from({ length: 9 }, (v, i) => {
        const result = {
            tradeType: tradeTypeName[i],
            typeKey: `HE${typeKeys[i]}`,
            num: nums[i],
            numKey: numKeys[i],
        }
        return result
    })

    // 2.前后和
    const afterData =
        Array.from({ length: 3 }, (v, i) => {
            return {
                tradeType: '前后和',
                typeKey: 'QHH',
                num: ['前后', '和', '后多'][i],
                numKey: ['QD', 'HD', 'HE'][i],
            }
        })

    // 3. 单双和

    const dsData =
        Array.from({ length: 3 }, (v, i) => {
            return {
                tradeType: '单双和',
                typeKey: 'DSH',
                num: ['单多', '和', '双多'][i],
                numKey: ['DD', 'SD', 'HE'][i],
            }
        })


    // 4. 五行

    const wxData = Array.from({ length: 5 }, (v, i) => {
        return {
            tradeType: '五行',
            typeKey: 'WX',
            num: ['金', '木', '水', '火', '土'][i],
            numKey: ['J', 'M', 'S', 'H', 'T'][i],
        }
    })

    // 正码 -----------------------------------------------------


    const numsData = Array.from({ length: 80 }, (v, i) => {
        return {
            tradeType: '正码',
            typeKey: 'NUM',
            num: i + 1,
            numKey: i + 1,

        }
    })
    return {
        db: [
            {
                list: allHeData
            },
            {
                list: dsData
            },
            {
                list: afterData
            },
            {
                list: wxData
            },
        ],
        zm: [
            {
                list: numsData
            },
        ],
    }
}

const pk10 = () => {
    const chineseSortName = ['冠军', '亚军', '第三名', '第四名', '第五名', '第六名', '第七名', '第八名', '第九名', '第十名', '亚冠和']
    const chineseBasicName = ['大', '小', '单', '双', '龙', '虎']
    const tradeTypeName = ['大小', '大小', '单双', '单双', '龙虎', '龙虎']
    const playerTypeName = ['DX', 'DX', 'DS', 'DS', 'LH', 'LH']
    const playNumName = ['D', 'X', 'D', 'S', 'L', 'H']


    // 冠亚和 号码 3-19
    const crownHe = Array.from({ length: 20 }, (v, i) => {
        if (i > 16) {
            return {
                isEmpty: true,
                width: 'width-5'
            }
        }
        return {
            tradeType: '冠亚和',
            typeKey: 'HE',
            numKey: i + 3,
            num: i + 3,
        }
    })

    // 冠亚和 大小单双
    const crownHeDXDS = Array.from({ length: 4 }, (v, i) => {
        return {
            tradeType: `冠亚和`,
            typeKey: `HE${playerTypeName[i]}`,
            numKey: ['D', 'X', 'D', 'S'][i],
            num: `${chineseBasicName[i]}`,
        }
    })

    // 号码函数 冠军 1-10 亚军 1-10 ...
    const combineNum = (num, len = 10) => {
        return Array.from({ length: len }, (v, i) => {
            return {
                typeKey: `NUM${num}`,
                tradeType: `${chineseSortName[num - 1]}`,
                numKey: i + 1,
                num: i + 1,
            }
        })
    }

    // 大小单双龙虎 组合函数 冠军大小单双龙虎 12345 有龙虎
    const combineDXLH = (num, len = 6) => {
        return Array.from({ length: num <= 5 ? len : 4 }, (v, i) => {
            let obj = {
                tradeType: `${chineseSortName[num - 1]}`,
                typeKey: `NUM${num}${playerTypeName[i]}`,
                numKey: `${playNumName[i]}`,
                num: `${chineseBasicName[i]}`,
            }
            return obj
        })
    }


    //------------------- 组合 -----------------//
    // 两面盘 排名 1-10 ----------------
    const dbSortData = Array.from({ length: 10 }, (v, i) => {
        return {
            list: combineDXLH(i + 1)
        }
    })

    // 冠亚和
    const crownHeData = {
        list: crownHe
    }

    // 单号 1-10 号码1-10 -------------------------
    const singleNumData = Array.from({ length: 10 }, (v, i) => {
        return {
            list: combineNum(i + 1)
        }
    })

    // 冠亚军组合------------------------
    const dbCrownHe = {
        list: crownHeDXDS
    }
    const crownHeSize = {
        list: crownHeDXDS
    }
    const crownNUm1Data = {
        list: [
            ...combineNum(1),
            ...combineDXLH(1)
        ]
    }
    const crownNUm2Data = {
        list: [
            ...combineNum(2),
            ...combineDXLH(2)
        ]
    }

    // 三四五六名 -----------------------

    const threeAndSixData = Array.from({ length: 4 }, (v, i) => {
        return {
            list: [
                ...combineNum(i + 3),
                ...combineDXLH(i + 3)
            ]
        }
    })

    const SevenAndTenData = Array.from({ length: 4 }, (v, i) => {
        return {
            list: [
                ...combineNum(i + 7),
                ...combineDXLH(i + 7)
            ]
        }
    })
    return {
        db: dbSortData.concat(dbCrownHe),
        odd: singleNumData,
        group: [
            crownHeData, // 冠亚和
            crownHeSize, // 冠亚军和
            crownNUm1Data, // 冠军
            crownNUm2Data, // 亚军
        ],
        ts: threeAndSixData,
        st: SevenAndTenData
    }
}
const l1x5 = () => {
    const chineseNumberName = ['第一球', '第二球', '第三球', '第四球', '第五球']
    const chineseBasicName = ['大', '小', '单', '双', '龙', '虎']
    const playerTypeName = ['DX', 'DX', 'DS', 'DS', 'WS', 'WS', 'LH', 'LH']
    const playNumName = ['D', 'X', 'D', 'S', 'D', 'X', 'L', 'H']

    // 号码函数 冠军 1-10 亚军 1-10 ...
    const combineNum = (num, len = 10) => {
        return Array.from({ length: len }, (v, i) => {
            if (i > 10) {
                return {
                    isEmpty: true,
                    width: 'width-6'
                }
            }
            return {
                tradeType: `${chineseNumberName[num - 1]}`,
                typeKey: `NUM${num}`,
                numKey: i + 1,
                num: i + 1,
            }
        })
    }

    // 大小单双 组合函数
    const combineDXLH = (num, len = 6) => {
        return Array.from({ length: num <= 5 ? len : 4 }, (v, i) => {
            return {
                tradeType: `${chineseNumberName[num - 1]}`,
                typeKey: `NUM${num}${playerTypeName[i]}`,
                numKey: `${playNumName[i]}`,
                num: `${chineseBasicName[i]}`,
            }
        })
    }

    // -----------------------------------default data----------------------------------------------------

    const chieseNames = ['大', '小', '单', '双', '尾大', '尾小', '龙', '虎']
    const tradeTypeName = ['大小', '大小', '单双', '单双', '尾数', '尾数', '龙虎', '龙虎']
    // 两面盘 总和
    const dbSortData = Array.from({ length: 8 }, (v, i) => {
        return {
            tradeType: `总和`,
            typeKey: `${i < 6 ? 'HE' : 'NUM'}${playerTypeName[i]}`,
            numKey: `${playNumName[i]}`,
            num: i < 6 ? `${chieseNames[i]}` : chieseNames[i],
        }
    })

    // 第一 五 球


    const singleNumData = Array.from({ length: 5 }, (v, i) => {
        return {
            list: combineDXLH(i + 1, 4)
        }
    })

    // 单号------------------------

    const singleData = Array.from({ length: 5 }, (v, i) => {
        return {
            list: combineNum(i + 1, 12)
        }
    })

    // 
    const zyData = Array.from({ length: 12 }, (v, i) => {
        if (i > 10) {
            return {
                isEmpty: true,
                width: 'width-6'
            }
        }
        return {
            tradeType: `中一`,
            typeKey: 'ZY',
            numKey: i + 1,
            num: i + 1,
        }
    })

    return {
        // 两面
        db: [
            {
                list: dbSortData
            },
            ...singleNumData
        ],
        odd: singleData,
        oneTone: [{
            list: zyData
        }],
    }
}

const lhc = () => {
    const lottName = {
        TMA: '特码A',
        TMB: '特码B',
        ZM: '正码A',
        ZMB: '正码B',
        ZMT1: '正一特',
        ZMT2: '正二特',
        ZMT3: '正三特',
        ZMT4: '正四特',
        ZMT5: '正五特',
        ZMT6: '正六特',
        LM3QZ: '三全中',
        LM3Z2: '三中二',
        LM2QZ: '二全中',
        LM2ZT: '二中特',
        LMTC: '特串',
        LM4QZ: '四全中',
        YXZ: '一肖中',
        WSZ: '尾数中',
        TX: '特肖',
        HX2: '二肖',
        HX3: '三肖',
        HX4: '四肖',
        HX5: '五肖',
        HX6: '六肖',
        HX7: '七肖',
        LX2: '二肖连中',
        LX3: '三肖连中',
        LX4: '四肖连中',
        LX5: '五肖连中',
        LW2: '二尾连中',
        LW3: '三尾连中',
        LW4: '四尾连中',
        LW5: '五尾连中',
        '5BZ': '五不中',
        '6BZ': '六不中',
        '7BZ': '七不中',
        '8BZ': '八不中',
        '9BZ': '九不中',
        '10BZ': '十不中',
        '4Z1': '四中一',
        '5Z1': '五中一',
        '6Z1': '六中一',
        '7Z1': '七中一',
        '8Z1': '八中一',
        '9Z1': '九中一',
        '10Z1': '十中一',
        LXBZ2: "二肖连不中",
        LXBZ3: "三肖连不中",
        LXBZ4: "四肖连不中",
        LXBZ5: "五肖连不中",
        LWBZ2: "二尾连不中",
        LWBZ3: "三尾连不中",
        LWBZ4: "四尾连不中",
        LWBZ5: "五尾连不中",
        RZ1: "一粒任中",
        RZ2: "二粒任中",
        RZ3: "三粒任中",
        RZ4: "四粒任中",
        RZ5: "五粒任中",
        RZ6: "六粒任中"
    }

    const specialNum = (str, len = 49, num = 49) => {
        return Array.from({ length: len }, (v, i) => {
                const numKey =  i + 1
                return {
                    tradeType: lottName[str],
                    typeKey: str,
                    num: numKey && numKey < 10 ? '0' + numKey : numKey,
                    numKey: numKey && numKey < 10 ? '0' + numKey : numKey,
                }
            })
    }

    const sizeName = ['大', '小', '单', '双', '合大', '合小', '合单', '合双', '尾大', '尾小']
    const sizeNameE = ['DXD', 'DXX', 'DSD', 'DSS', 'HDXD', 'HDXX', 'HDSD', 'HDSS', 'WD', 'WX']
    const specialSize = (len = 10) => {
        return Array.from({ length: len }, (v, i) => {
            return {
                tradeType: `特码`,
                typeKey: `TMSM`,
                num: sizeName[i],
                numKey: sizeNameE[i],
            }
        })
    }

    // 波胆
    const specialBo = Array.from({ length: 3 }, (v, i) => {
        return {
            tradeType: '特码',
            typeKey: 'TMBS',
            num: ['红', '蓝', '绿'][i],
            numKey: ['R', 'B', 'G'][i],
        }
    })

    // --------------正码 总大 小 单双-------------
    const zNumSum = () => {
        return Array.from({ length: 4 }, (v, i) => {
            return {
                tradeType: `总和`,
                typeKey: `ZHSM`,
                num: `${['大', '小', '单', '双'][i]}`,
                numKey: `${['DX', 'DX', 'DS', 'DS'][i]}${['D', 'X', 'D', 'S'][i]}`,
            }
        })
    }

    // -------------------------------特码生肖----------------------------------
    const chineseZodica = ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪']

    const specialZodica = (str = "TX") => {
        return Array.from({ length: chineseZodica.length }, (v, i) => {
            return {
                tradeType: lottName[str],
                typeKey: str,
                num: chineseZodica[i],
                numKey: i + 1,
            }
        })
    }

    // ==========================尾数========================
    const weiNum = (str = 'WSZ') => {
        return Array.from({ length: 10 }, (v, i) => {
            return {
                tradeType: lottName[str],
                typeKey: str,
                num: `${i}尾`,
                numKey: i,
            }
        })
    }
    // ----------------------------------半波--------------------------------------
    const createBanBo = () => {
        return Array.from({ length: 12 }, (v, i) => {
            const names = ['红', '蓝', '绿']
            const namesE = ['R', 'B', 'G']
            const sizes = ['单', '双', '大', '小']
            const sizesE = ['DSD', 'DSS', 'DXD', 'DXX']

            return {
                tradeType: '半波',
                typeKey: `BB`,
                num: combineStr(names, sizes)[i],
                numKey: combineStr(namesE, sizesE)[i],
            }
        })
    }

    const combineStr = (arr1, arr2) => {
        return arr1.reduce((pre, cur) => {
            const clone = pre.concat()
            clone.push(arr2.map(m => `${cur}${m}`))
            clone
            return flatDeep(clone, Infinity)
        }, [])
    }

    function flatDeep (arr, d = 1) {
        return d > 0 ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), []) : arr.slice()
    }

    //-------------正码1-6---------------//
    const zNumsList = ['单', '双', '大', '小', '合单', '合双', '合大', '合小', '尾大', '尾小']
    const zNumsListE = ['D', 'S', 'D', 'X', 'D', 'S', 'D', 'X', 'D', 'X']
    const zNumListTypes = ['DS', 'DS', 'DX', 'DX', 'HDS', 'HDS', 'HDX', 'HDX', 'WD', 'WX']
    const zmlottName = ['正码一', '正码二', '正码三', '正码四', '正码五', '正码六']

    const createZNumList = (num = 6) => {
        return Array.from({ length: zNumListTypes.length }, (vv, ii) => {
            return {
                tradeType: zmlottName[num - 1],
                typeKey: `ZM${num}`,
                num: zNumsList[ii],
                numKey: ii > 7 ? `${zNumListTypes[ii]}` : `${zNumListTypes[ii]}${zNumsListE[ii]}`,
            }
        })
    }

    const zNumsListColor = ['红波', '绿波', '蓝波']
    const zNumsListEColor = ['R', 'G', 'B']
    const zNumListTypesColor = ['R', 'G', 'B']

    const createZNumListColor = (num = 6) => {
        return Array.from({ length: zNumListTypesColor.length }, (vv, ii) => {
            return {
                tradeType: zmlottName[num - 1],
                typeKey: `ZM${num}`,
                num: zNumsListColor[ii],
                numKey: zNumsListEColor[ii],
            }
        })
    }
    const lxList = ['LX2', 'LX3', 'LX4', 'LX5']
    const hxList = ['HX2', 'HX3', 'HX4', 'HX5', 'HX6', 'HX7']
    const lwList = ['LW2', 'LW3', 'LW4', 'LW5']
    const zxzList = ['4Z1','5Z1', '6Z1', '7Z1', '8Z1', '9Z1', '10Z1']
    const tpzList = ['RZ1','RZ2', 'RZ3', 'RZ4', 'RZ5', 'RZ6']
    const zxbzList = ['5BZ', '6BZ', '7BZ', '8BZ', '9BZ', '10BZ']
    const lmList = ['LM3QZ', 'LM3Z2', 'LM2QZ', 'LMTC', 'LM2ZT', 'LM4QZ']
    const zmtList = ['ZMT1', 'ZMT2', 'ZMT3', 'ZMT4', 'ZMT5', 'ZMT6']
    const lxbzList = ['LXBZ2', 'LXBZ3', 'LXBZ4', 'LXBZ5']
    const lwbzList = ['LWBZ2', 'LWBZ3', 'LWBZ4', 'LWBZ5']
    const getLvList = (list, fc) => {
        let obj = {}
        list.forEach(item => {
            obj[item] = [{ list: fc(item) }]
        })
        return obj
    }

    const getHxList = function() {
        let valObj = {}
        hxList.forEach((item, i) => {
            valObj[item] = lottName[item]
        })
        return valObj
    }

    const zmList = ['zm161', 'zm162', 'zm163', 'zm164', 'zm165', 'zm166']
    const getZmList = (list) => {
        let obj = {}
        list.forEach((item, index) => {
            obj[item] = [{ list: createZNumList(index + 1) }, { list: createZNumListColor(index + 1) }]
        })
        return obj
    }
    return {
        // 特码A
        ta: [
            {
                list: specialNum('TMA')
            },
            {
                list: specialSize()
            },
            {
                list: specialBo
            }
        ],
        // 特码B
        tb: [
            {
                list: specialNum('TMB')
            },
            {
                list: specialSize()
            },
            {
                list: specialBo
            }
        ],
        // 正码
        zm: [
            {
                list: specialNum('ZM')
            },
            {
                list: zNumSum()
            },
        ],
        zmb: [
            {
                list: specialNum('ZMB')
            },
            {
                list: zNumSum()
            },
        ],
        ...getLvList(zmtList, specialNum),
        ...getLvList(lmList, specialNum),
        ...getLvList(zxbzList, specialNum),
        ...getLvList(zxzList, specialNum),
        ...getLvList(tpzList, specialNum),
        ...getZmList(zmList),
        tmsx: [
            {
                list: specialZodica()
            }
        ],
        sxws: [
            {
                list: specialZodica('YXZ')
            },
            {
                list: weiNum('WSZ')
            }
        ],
        xwbz: [
            {
                list: specialZodica('YXBZ')
            },
            {
                list: weiNum('WSBZ')
            }
        ],
        // ...getLvList(hxList, specialZodica),
        ...getLvList(lxList, specialZodica),
        ...getLvList(lwList, weiNum),
        ...getLvList(lxbzList, specialZodica),
        ...getLvList(lwbzList, weiNum),
        bb: [
            {
                list: createBanBo()
            }
        ],
    }
}

const hxName = {
    HX2: '二肖',
    HX3: '三肖',
    HX4: '四肖',
    HX5: '五肖',
    HX6: '六肖',
    HX7: '七肖',
}
const hxLhList = ['HX2', 'HX3', 'HX4', 'HX5', 'HX6', 'HX7']
const getHxList = function() {
    let valObj = {}
    hxLhList.forEach((item, i) => {
        valObj[item] = hxName[item]
    })
    return valObj
}

const dictionariesssc = getList(ssc())
const dictionarieskl10 = getList(kl10())
const dictionarieskl8 = getList(kl8())
const dictionariesk3 = getList(k3())
const dictionariespk10 = getList(pk10())
const dictionaries11x5 = getList(l1x5())
const dictionarieslhc = getList(lhc())

export const allType = {
    10001: list(dictionariesssc),
    10002: list(dictionarieskl10),
    10003: list(dictionariespk10),
    10004: list(dictionaries11x5),
    10005: {...list(dictionarieslhc), ...getHxList()},
    10006: list(dictionarieskl8),
    10007: list(dictionariesk3),
}

export const onlyAllType = {
    10001: onlyList(dictionariesssc),
    10002: onlyList(dictionarieskl10),
    10003: onlyList(dictionariespk10),
    10004: onlyList(dictionaries11x5),
    10005: onlyList(dictionarieslhc),
    10006: onlyList(dictionarieskl8),
    10007: onlyList(dictionariesk3),
}
